import React from 'react';
import { Link } from 'gatsby';
import ReactWOW from "react-wow";
import PreviewCompatibleImage from './PreviewCompatibleImage';
export const BlogRollTemplate = (props) => {
  const { edges: posts, totalCount } = props.data.allMarkdownRemark;

  return (
    totalCount ?
    <ReactWOW delay=".1s" animation="fadeInLeft">
      <div className="landing-page">
        <section className={"blog-area " + (props.withTitle ? 'section-blog' : '')}>
          <div className="container">
            {
              props.withTitle &&
              <div className="section-blog-title">
                <h2 className="heading-2">Latest insights.</h2>
                <a href="/blog">View all</a>
              </div>
            }

            <div className="row">
              {posts && posts.map(({ node: post }) => (
                  <div className={"col-lg-4 col-md-6"} key={post.id}>
                    <article className="single-blog-post">
                      {post?.frontmatter?.featuredimage && (
                        <Link to={post.fields.slug}>
                          <div className="post-image">
                            <PreviewCompatibleImage
                              imageInfo={{
                                image: post.frontmatter.featuredimage,
                                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                width:
                                  post.frontmatter.featuredimage.childImageSharp
                                    .gatsbyImageData.width,
                                height:
                                  post.frontmatter.featuredimage.childImageSharp
                                    .gatsbyImageData.height,
                              }}
                            />
                          </div>
                        </Link>
                      )}
                      <div className="post-content">
                        <ul className="post-meta d-flex justify-content-between align-items-center">
                          <li>
                            <i className="flaticon-calendar"></i>
                            <span className="ml-2">{post.frontmatter.date}</span>
                          </li>
                        </ul>
                        <h3>
                          <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                        </h3>
                        <p className="text-left pt-3 text-3">
                          <span className="description">{post.frontmatter.description.trim() || post.excerpt}</span>
                          <br />
                          <Link className="button" to={post.fields.slug}>
                            Keep Reading <i class='flaticon-right-1'></i>
                          </Link>
                        </p>
                      </div>
                    </article>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
      </div>
    </ReactWOW>
    : null
  )
}
