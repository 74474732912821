import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import Banner from "../components/Index/Banner";
import MobileBanner from "../components/Index/MobileBanner";
import LandingPage from "../components/Index/LandingPage";
import Carousel1 from "../components/Index/LandingPageCarousal";
import BlogLandingPage from "../components/Blog/BlogLandingPage";

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <MobileBanner />
      <LandingPage />
      <BlogLandingPage />
      <Carousel1 />
      <Footer />
    </Layout>
  );
};

export default Home;
