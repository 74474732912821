import React from "react";
import ReactWOW from "react-wow";
import { Link, useStaticQuery, graphql } from "gatsby";
import BillTrestrail from "../../assets/images/hyfen/team/Bill_Trestrail.png";
import ScottGoodwin from "../../assets/images/hyfen/team/Scott_Goodwin.jpeg";
import KirstinSchneider from "../../assets/images/hyfen/team/Kirstin_Schneider.png";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <section className="section1">
        <div className="container">
          <div className="align-items-center d-flex justify-content-around justify-content-md-start">
            <div className="row">
              <div className="col-12 col-md-7">
                <ReactWOW delay=".1s" animation="fadeInLeft">
                  <h1 className="pb-5 heading-2">The Hyfen Eight<sup className="registered">&reg;</sup></h1>
                </ReactWOW>

                <ReactWOW delay=".1s" animation="fadeInLeft">
                  <div>
                    <p>
                      We’ve identified 8 dimensions of intangibles that predict future outperformance. Use the Hyfen8 platform to measure and optimise each
                      dimension, and add huge value to your organisation.
                      <Link to="/framework-intangibles" className="blue-focus-ring">
                        Learn about the Hyfen Eight
                        <sup className="registered">&reg;</sup> here
                      </Link>
                      .
                    </p>
                  </div>
                </ReactWOW>
              </div>
              <div className="col-12 col-md-5 d-flex align-items-center justify-content-md-end pt-md-0 pt-5">
                <ReactWOW delay=".1s" animation="fadeInRight">
                  <div className="">
                    <h2>
                      The Hyfen Eight<sup className="registered">&reg;</sup>
                    </h2>
                    <ul>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#OperatingModel" className="blue-focus-ring">
                          Operating Model
                        </Link>
                      </li>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#Ecosystem" className="blue-focus-ring">
                          Ecosystem
                        </Link>
                      </li>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#Brand" className="blue-focus-ring">
                          Brand
                        </Link>
                      </li>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#Data" className="blue-focus-ring">
                          Data
                        </Link>
                      </li>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#Knowhow" className="blue-focus-ring">
                          Knowhow
                        </Link>
                      </li>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#Rights" className="blue-focus-ring">
                          Rights
                        </Link>
                      </li>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#People" className="blue-focus-ring">
                          People
                        </Link>
                      </li>
                      <li className="mb-3">
                        <span></span>
                        <Link to="/framework-intangibles/#BusinessModel" className="blue-focus-ring">
                          Business Model
                        </Link>
                      </li>
                    </ul>
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section2">
        <div className="container align-items-lg-center d-flex justify-content-around flex-column text-lg-center">
          <ReactWOW delay=".1s" animation="fadeInLeft">
            <h1 className="pb-5 heading-2">Don't just take our word for it.</h1>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInLeft">
            <h4 className="pb-5">Here’s what our clients and industry experts have to say.</h4>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInLeft">
            <div className="row">
              <div className="column col-sm-6 col-lg-4">
                <div className="testimonial">
                  <div className="d-flex align-items-center pb-3">
                    <img src={BillTrestrail} alt="testimonial" />
                    <div className="d-flex flex-column text-left ml-3">
                      <h6 className="heading-4">Bill Trestrail</h6>
                      <h6>Board and Growth Advisor</h6>
                    </div>
                  </div>
                  <p className="text-left text-3">
                    <span>&ldquo;</span>Intangibles are the hidden drivers of growth in today’s business models. The Hyfen8 strategy analytics platform stands
                    out as an invaluable resource for founders, investors and advisors to fast-growth businesses. Hyfen8 analytics is about remaining relevant
                    now, and even more importantly, capitalising on the predictive power that intangible assets provide for future success.
                    <span>&rdquo;</span>
                  </p>
                </div>
              </div>
              <div className="column col-sm-6 col-lg-4">
                <div className="testimonial">
                  <div className="d-flex align-items-center pb-3">
                    <img src={ScottGoodwin} alt="testimonial" />
                    <div className="d-flex flex-column text-left ml-3">
                      <h6 className="heading-4">Scott Goodwin</h6>
                      <h6>CFO and COO, IOT entrepreneur</h6>
                    </div>
                  </div>
                  <p className="text-left text-3">
                    <span>&ldquo;</span>At a practical level, most entrepreneurs just don’t have the time to figure this sort of stuff out. Hyfen8 strives to
                    make it simple for people like you and me to work out what matters most in the businesses we’re building. Hyfen8 helps us turn our daily
                    efforts and hard-won innovations into protected and tradable enterprise value.
                    <span>&rdquo;</span>
                  </p>
                </div>
              </div>
              <div className="column col-sm-6 col-lg-4">
                <div className="testimonial">
                  <div className="d-flex align-items-center pb-3">
                    <img src={KirstinSchneider} alt="testimonial" />
                    <div className="d-flex flex-column text-left ml-3">
                      <h6 className="heading-4">Kirstin Schneider</h6>
                      <h6>Founder Hyfen8</h6>
                    </div>
                  </div>
                  <p className="text-left text-3">
                    <span>&ldquo;</span>The data shows that throughout COVID, it was the businesses with the highest book-to-market value ratio, which is where
                    intangible assets show up, that outperformed their peers. The strong performance of intangible assets as drivers of value through COVID
                    represents a familiar pattern - simply put, organisations that aren’t looking at their intangible asset potential are missing out in a big
                    way.<span>&rdquo;</span>
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
