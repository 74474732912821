import React from "react";
import ReactWOW from "react-wow";

import Loadable from "@loadable/component";
const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: false,
  autoplaySpeed: 2000,
  navSpeed: 2000,
  margin: 30,
  navText: ["<i class='flaticon-left-1'></i>", "<i class='flaticon-right-1'></i>"],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
};

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <section className="articles-area pt-100 pb-md-5">
      <ReactWOW delay=".1s" animation="fadeInLeft">
        <div className="container">
          <h3 className="pb-md-3 pb-0">
            <span>Expert opinion –</span> on point insight from global experts
          </h3>

          {display ? (
            <OwlCarousel className="articles-slides owl-carousel owl-theme" {...options}>
              <div className="single-article-item mt-5">
                <p className="text-left">
                  “The evidence is stacking up, in an age increasingly driven by innovation and knowledge, that firms and sectors that invest most heavily in
                  intangibles are reinforcing and deepening their competitive advantage and achieving the highest rates of growth in gross value added.”
                </p>

                <div className="title d-flex justify-content-between py-3 mb-1">
                  <h6 className="heading-4 text-left">
                    Eric Hazan et al, McKinsey and Company <br />
                    <a
                      className="blue-focus-ring"
                      href="https://www.mckinsey.com/business-functions/marketing-and-sales/our-insights/getting-tangible-about-intangibles-the-future-of-growth-and-productivity"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Getting Intangible About Intangibles, 2021
                    </a>
                  </h6>
                </div>
              </div>

              <div className="single-article-item  mt-5">
                <p className="text-left">
                  “The valuation of intangible assets such as brands, goodwill and intellectual property is becoming increasingly important in the context of
                  cross-border mergers and acquisitions, joint venture arrangements and for investment and lending decisions.”
                </p>

                <div className="title d-flex justify-content-between py-3 mb-1">
                  <h6 className="heading-4 text-left">
                    Chris Thorne, Chairman of the International Valuation Standards Board (IVSC), 2021 <br />
                    <a className="blue-focus-ring" href="https://www.ivsc.org/time-to-get-tangible-about-intangible-assets/" rel="noreferrer" target="_blank">
                      International Valuation Standards Board - Intangible Asset Series
                    </a>
                  </h6>
                </div>
              </div>

              <div className="single-article-item  mt-5">
                <p className="text-left">
                  “Early in the twenty-first century, a quiet revolution occurred. For the first time, the major developed economies began to invest more in
                  intangible assets, like design, branding, and software, than in tangible assets like machinery, buildings and computers. For all sorts of
                  businesses, the ability to deploy assets that one can neither see nor touch is increasingly the main source of long-term success.”
                </p>

                <div className="title d-flex justify-content-between py-3 mb-1">
                  <h6 className="heading-4 text-left">
                    Jonathan Haskel and Stian Westlake, “Capitalism without Capital”, Princeton University Press 2018 <br />
                    <a
                      className="blue-focus-ring"
                      href="https://press.princeton.edu/books/hardcover/9780691175034/capitalism-without-capital"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Capitalism without Capital: the rise of the intangible economy
                    </a>
                  </h6>
                </div>
              </div>

              <div className="single-article-item  mt-5">
                <p className="text-left">
                  “[O]ver the last 100 years or so, financial reports have become less useful in capital market decisions. Our current financial accounting
                  model cannot capture the principle value creator for digital companies: increasing return to scale on intangible investments.”
                </p>

                <div className="title d-flex justify-content-between py-3 mb-1">
                  <h6 className="heading-4 text-left">
                    Vijay Govindarajan et al, Harvard Business Review, 2018/02 <br />
                    <a
                      className="blue-focus-ring"
                      href="https://hbr.org/2018/02/why-financial-statements-dont-work-for-digital-companies"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Why Financial Statements Don’t Work for Digital Companies
                    </a>
                  </h6>
                </div>
              </div>

              <div className="single-article-item  mt-5">
                <p className="text-left">
                  “In the US, there have been a number of legal proceedings filed against directors as a result of poor governance of intangible assets. For
                  example, shareholders of RSA Security Inc alleged that directors were in breach of their duty because the company had not filed for
                  international patent protection, thereby enabling competitors to use the technology in international markets, significantly undermining
                  financial returns.”
                </p>

                <div className="title d-flex justify-content-between py-3 mb-1">
                  <h6 className="heading-4 text-left">
                    Karen Hallenstein, The Governance Institute <br />
                    <a
                      className="blue-focus-ring"
                      href="https://www.governanceinstitute.com.au/resources/governance-directions/volume-72-number-7/intangible-assets-and-directors-duties-why-directors-should-understand-the-economic-value-of-their-intangibles/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      The Governance Institute
                    </a>
                  </h6>
                </div>
              </div>
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
      </ReactWOW>
    </section>
  );
};

export default Testimonials;
