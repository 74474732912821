import React from "react";
import ReactWOW from "react-wow";
import { Link, useStaticQuery, graphql } from "gatsby";
const MobileBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          portalUrl
        }
      }
    }
  `);
  return (
    <div className="mobile-banner pt-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="landing-pg-banner-content pg-banner-content mobile-banner-content py-5">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1 className="heading-1 pb-2">Discover the Untapped Power in your Business</h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1 className="text-1">Intangible assets drive value and outperformance – yet often remain hidden.</h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <div className="py-4">
                  <p>
                    The Hyfen8<sup className="trademark">&trade;</sup> platform identifies the strength, scope and gaps in your intangible assets. Giving you
                    the information you need to maximise value and achieve your goals.
                  </p>
                  <p>
                    <b>Start with your free assessment of your People and Knowhow assets now. </b>
                  </p>
                </div>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <div className="btn-box">
                  <Link to={data.site.siteMetadata.portalUrl} target="/blank" className="primary-btn cta-button">
                    Start now<span className="effect"></span>
                  </Link>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBanner;
